import React from 'react';
//for title of booked {day.event && <div className='event'>booked</div>}

// get Roles from local storage

const user = JSON.parse(localStorage.getItem("user"));
var mod = false; var u1 = false; var isYello = false;
var btitle = "booked";

if (user) {
  if ( user.roles.includes("ROLE_MODERATOR") ) { mod = true; }
  u1 = true;
}
//if (mod) { console.log("moderator") } else { console.log("user") }

  export const Day = ({ day, onClick }) => {
    const className = `day ${day.value === 'padding' ? 'padding' : ''} ${day.isCurrentDay ? 'currentDay' : ''}`;
    if (day.event) { 
      if (mod) {
        btitle = day.event.title;
      } else if (u1) {
        if ( user.bname === day.event.title ) { 
          btitle = day.event.title; isYello = true;
        }
        else { btitle = "booked"; isYello = false; }
      }
     }
    return (
      <div onClick={onClick} className={className}>
        {day.value === 'padding' ? '' : day.value}
        {day.event && <div className='event' style={{backgroundColor: isYello ? "#ffc107" : "#0dcaf0"}}>
            {btitle}
        </div>}       
      </div>
    );
  };

