import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";

import AuthService from "../services/auth.service";

import HutCal from "../hutcalender/App";

const BoardHutCalender = () => {
const [content, setContent] = useState("");
const currentUser = AuthService.getCurrentUser();

  useEffect(() => {
    UserService.getCalenderHutBoard().then(
      (response) => {
        setContent(response.data);
        //setContent("Calender Board Test");
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);
  //console.log("BoardHutCalender.js content is " + JSON.stringify(content));
  return (
    <div className="cal-container">
      <header className="jumbotron">
        <h3>{content}</h3>
        {currentUser.roles.includes("ROLE_HUTUSER") && <HutCal />}
      </header>
    </div>
  );
};

export default BoardHutCalender;