import React, { useState, useEffect } from 'react';

import { CalendarHeader } from '../calender/CalendarHeader';
import { Day } from '../calender/Day';

import { useDate } from '../calender/hooks/useDate';

import '../calender/style.css';

const HutCal = () => {

  const [nav, setNav] = useState(0);
  const setClicked = useState();
  const [bookings, setBookings] = useState([]);
  const { days, dateDisplay } = useDate(bookings, nav);
  const user = JSON.parse(localStorage.getItem("user"));

  
  useEffect(() => {
    if ( user.roles.includes("ROLE_HUTUSER") ) {
      fetch("https://www.hut18.co.uk/api/test/caldata")
      .then(res => {
        return res.json();
      })
      .then(data => {
        //console.log(data);
        setBookings(data);
      })
    }
  }, []);
  
  return(
    <>
    <div id="container">
      <CalendarHeader 
        dateDisplay={dateDisplay}
        onNext={() => setNav(nav + 1)}
        onBack={() => setNav(nav - 1)}
      />

      <div id="weekdays">
        <div>Sunday</div>
        <div>Monday</div>
        <div>Tuesday</div>
        <div>Wednesday</div>
        <div>Thursday</div>
        <div>Friday</div>
        <div>Saturday</div>
      </div>

      <div id="calendar">
        {days.map((d, index) => (
          <Day
            key={index}
            day={d}
            onClick={() => {
              if (d.value !== 'padding') {
                setClicked(d.bdate);
              }
            }}
          />
        ))}
      </div>
    </div>

  </>
);

}
export default HutCal;
