import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";

import beachpic from'../images/beachview01.jpg';

const Home = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  const divStyle = {
    width: '99%',
    height: '700px',
    backgroundImage: `url(${beachpic})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  };

  return (
    <div className="container">
      <div className="cComponent" style={divStyle} >
        <h1 align="center"></h1>
      </div>
    </div>
  );
};

export default Home;
