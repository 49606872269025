import React from "react";
import AuthService from "../services/auth.service";

const Profile = () => {
  const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      if (currentUser.roles.includes("ROLE_HUTUSER")) {
        return (
          <div className="container">
          <header className="jumbotron">
          <h3>
            Welcome {currentUser.username}
          </h3>
          </header>
          </div>
        );
      } else {
        return (
          <div className="container">
        <header className="jumbotron">
        <h3>
          {currentUser.username}, please ask a hutter if you need access to the hut calender.
        </h3>
      </header>
      </div>
        );
      }
    }
  return null;
}

export default Profile;
